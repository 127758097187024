/** With Error Boundary and Logging */

import { AccountActions } from '@signatu/app'
import { LogType } from '@signatu/common-lib'
import { connect } from 'react-redux'
import Container from './container.component'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
    log: (level: LogType, message: string) => dispatch(AccountActions.log(level, message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Container)
