import React from 'react';
import { FC } from 'react';
import ErrorBoundary from './error-boundary';
import { LogType } from '@signatu/common-lib';
import { RouteComponentProps } from '@reach/router';

export interface ContainerProps extends RouteComponentProps {
  className?: string
  log(level: LogType, message: string)
}

const Container: FC<ContainerProps> = ({ children, log, className }) => (
  <div className={className}>
    <ErrorBoundary log={log}>
        {children}
    </ErrorBoundary>    
  </div>
)

export default Container
