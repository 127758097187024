import { createStyles, Theme, WithStyles, withStyles, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import classNames from 'classnames'
import { User } from '@signatu/models'
import { Link } from 'gatsby'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUserOutlined'
import UserIcon from '@material-ui/icons/AccountCircleOutlined'

export interface UserBadgeProps {
    className?: string
    user: User
}

const styles = (theme: Theme) => createStyles({
    root: {
        cursor: "pointer"
    },
    heading: {
        display: "flex",
        flexDirection: "row",
        justifyItems: "center"
    }
})

function UserBadge(props: UserBadgeProps & WithStyles<typeof styles>) {
    const { classes, className, user } = props

    if (!user) {
        return null
    }
    const Icon = user.emailVerified ? VerifiedUserIcon : UserIcon
    return (
        <div className={classNames(className, classes.root)}>
            <Link to="/app/account">
                <Typography className={classes.heading} color="secondary" variant="subtitle1"><Icon /> &nbsp; {user.email}</Typography>
            </Link>
        </div>
    )
}

export default withStyles(styles)(UserBadge)
