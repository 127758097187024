/** 
 * Top-level content container 
 * 
 * The main purpose is to handle errors in the content. 
 * 
 */
import React from 'react'
import { LogType } from '@signatu/common-lib'
import { WithStyles, withStyles, createStyles, Theme, Typography} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import ErrorOutline from '@material-ui/icons/ErrorOutline'

export interface TopContentProps {
    log(logLevel: LogType, message: string)
}

export interface TopContentState {
     error: boolean
}

const styles = (theme: Theme) => createStyles({
    root: {
        margin: theme.spacing(4), 
        display: "flex", 
        flex: "1", 
        flexDirection: "column", 
        justifyContent: "center", 
        alignItems: "center"
    }, 
    warning: {
        color: red["500"], 
        fontSize: "160px", 
        marginBottom: theme.spacing(4)
    }
})

class ErrorBoundary extends React.Component<TopContentProps & WithStyles<typeof styles>, TopContentState> {
     constructor(props) {
         super(props)
         this.state = {
            error: false
         }
     }
     componentDidCatch(error, info) {
        const { log } = this.props
        this.setState({error: true})
        if ( log ) {
            log(LogType.ERROR, error.stack ? error.stack.toString() : error.toString())
        }        
     }
     
     componentDidUpdate(prevProps) {
        if (this.props.children !== prevProps.children) {
            this.setState({error: false})
        }
     }

     render() {
         const { error } = this.state
         const { classes, children } = this.props
         if ( error ) {
            return (
                <div className={classes.root}>
                    <div className={classes.warning}>
                        <ErrorOutline className={classes.warning} />
                    </div>
                    <Typography variant="h6">
                        Something went wrong... please try again. If the problem persists, contact <a href="mailto:support@signatu.com">support@signatu.com</a>. 
                    </Typography>
                </div>
            )
         }
         return children
     }
 }

export default withStyles(styles)(ErrorBoundary)
