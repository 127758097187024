import { AppBar, Button, Fab, makeStyles, Menu, MenuItem, Theme, Toolbar, Typography } from '@material-ui/core'
import { Location } from '@reach/router'
import { LogType } from '@signatu/common-lib'
import { Signatu as SignatuIcon } from '@signatu/icons'
import cn from 'classnames'
import { Link, navigate } from 'gatsby'
import React, { FC, useState } from 'react'
import packageJSON from '../../../package.json'
import menu, { Menu as IMenu } from '../../data/top-menu'
import UserBadge from '../account/user-badge.container'

export interface TopMenuProps {
    config: any
    title?: string
    breadcrumbs?: any
    user: any
    userId?: number
    userRoles: any[]
    isInLoginProcess: boolean
    isLoggedIn: boolean
    taskCount: number

    navigate(path: string)
    loadUser(userId: string)
    log(level: LogType, message: string)
    logout?()
}

export interface TopMenuState {
    drawerOpen: boolean
}
const useStyles = makeStyles((theme: Theme) => ({
    block: {
        display: 'flex',
        flexDirection: 'row',
    },
    logoContainer: {
        [theme.breakpoints.down('sm')]: {
            flex: 1,
            justifyContent: 'center',
        },
    },
    toolbar: {
        ...theme.mixins.toolbar,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    navIconHide: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.contrastText,
        fontSize: '14px',
    },
    button: {
        paddingLeft: `16px !important`,
        paddingRight: `16px !important`,
    },
    appBar: {
        height: 64,
        zIndex: (theme.zIndex.drawer + 1) as any,
    },
    margin: {
        margin: theme.spacing(1),
    },
    menuLinkContainer: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    menuButton: {
        paddingLeft: `16px !important`,
        paddingRight: `16px !important`,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    menuLink: {
        display: 'flex',
        color: theme.palette.secondary.main,
        textDecoration: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    devInfo: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    menuItem: {
        color: theme.palette.primary.contrastText,
    },
    logo: {
        display: 'inline-flex',
        alignItems: 'center',
        fontSize: '24px',
        whiteSpace: 'nowrap' as any,
        color: theme.palette.primary.contrastText,
    },
    icon: {
        position: 'relative' as any,
        top: '3px',
    },
    appBarButton: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
}))

const TopMenu: FC<TopMenuProps> = (props) => {
    const classes = useStyles(props)
    const { config, isLoggedIn, user, title = 'signatu' } = props
    const [showMenu, setShowMenu] = useState({})
    const [anchorEl, setAnchorEl] = useState(null)

    const handleShowMenu = (event, link: string) => {
        setAnchorEl(event.target)
        setShowMenu({ ...showMenu, [link]: true })
    }
    const handleSelectMenu = (m: IMenu) => {
        setShowMenu({ ...showMenu, [m.link]: false })
        navigate(m.link)
    }
    const renderMenu = (m: IMenu, location) => {
        /** Hide when in /app? */
        if (m.hideInApp && location.pathname.startsWith('/app')) {
            return null
        }

        if (typeof m.loggedIn === 'boolean') {
            /** Requires login, and not logged in */
            if (m.loggedIn && !isLoggedIn) {
                return null
            }
            /** Requires NOT logged in, and logged in */
            if (!m.loggedIn && isLoggedIn) {
                return null
            }
        }

        return (
            <React.Fragment key={m.link}>
                {(!m.children || m.children.length === 0) &&
                    (m.useButton ? (
                        <Fab
                            className={classes.menuButton}
                            variant="extended"
                            color="secondary"
                            size="small"
                            onClick={() => navigate(m.link)}
                        >
                            {m.icon ? <>{m.icon} &nbsp;</> : null} {m.name}
                        </Fab>
                    ) : (
                        <Link to={m.link} className={classes.menuLink}>
                            {m.icon} {m.name}
                        </Link>
                    ))}
                {m.children && (
                    <>
                        <Button className={classes.link} onClick={(ev) => handleShowMenu(ev, m.link)}>
                            {m.name}
                        </Button>
                        <Menu
                            key={m.link}
                            anchorEl={anchorEl}
                            open={Boolean(showMenu[m.link])}
                            onClose={() => setShowMenu(false)}
                        >
                            {m.children.map((c) => (
                                <MenuItem button={true} key={c.link} onClick={() => handleSelectMenu(c)}>
                                    {renderMenu(c, location)}
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                )}
            </React.Fragment>
        )
    }
    /** Start render */
    return (
        <Location>
            {({ location }) => (
                <AppBar position="sticky" className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                        <div className={cn(classes.block, classes.logoContainer)}>
                            <Link to="/" className={classes.block}>
                                <Typography color="secondary" variant="h6" className={classes.logo}>
                                    <SignatuIcon /> &nbsp;
                                    {title}
                                </Typography>
                            </Link>
                        </div>

                        {menu.map((m) => renderMenu(m, location))}

                        <UserBadge />

                        <div className={classes.devInfo}>
                            {config.env !== 'production' && `v${packageJSON.version} (${config.env})`}
                        </div>
                    </Toolbar>
                </AppBar>
            )}
        </Location>
    )
}

export default TopMenu
