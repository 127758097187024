import '@signatu/styles/fonts/fonts.css'

import { useGlobalStyles } from '@signatu/styles'
import React, { FC } from 'react'

import LoadUserContainer from '../components/account/load-user.container'

interface LayoutRootProps {
    className?: string
}

const LayoutRoot: FC<LayoutRootProps> = (props) => {
    const { children, className } = props
    const globalClasses = useGlobalStyles(props)

    return (
        <LoadUserContainer className={className}>
            <div className={globalClasses.root}>{children}</div>
        </LoadUserContainer>
    )
}

export default LayoutRoot
