import { ConsentConfigContainer } from '@signatu/app'
import { graphql, useStaticQuery } from 'gatsby'
import { FC } from 'react'
import Helmet from 'react-helmet'

import { RouteComponentProps } from '@reach/router'
import Top from '../components/menus/top.container'
import LayoutMain from './layout-main'
import LayoutRoot from './layout-root'

interface StaticQueryProps {
    site: {
        siteMetadata: {
            title: string
            description: string
        }
    }
}

export interface LayoutProps extends RouteComponentProps {
    frontmatter?: {
        title?: string
        description?: string
        keywords?: string
        allowEval?: boolean

        consentContext?: 'signatu' | 'customer'
    }
}

const IndexLayout: FC<LayoutProps> = ({ children, frontmatter = {} }) => {
    /**
     * @todo this fails
     * @see https://github.com/signatu/signatu/issues/4108
     * @see https://github.com/gatsbyjs/gatsby/pull/6452
     */
    // const data: StaticQueryProps = useStaticQuery(graphql`
    //     query IndexLayoutQuery {
    //         site {
    //             siteMetadata {
    //                 title
    //                 description
    //             }
    //         }
    //     }
    // `)

    const data = { site: { siteMetadata: { title: 'Signatu' } } }
    const meta: Record<string, string>[] = [
        {
            name: 'keywords',
            content: `gdpr, privacy, consent, trackers, privacy policy${
                frontmatter.keywords ? ', ' + frontmatter.keywords : ''
            }`,
        },
    ]

    const consentContext = frontmatter.consentContext ? frontmatter.consentContext : 'customer'
    return (
        <ConsentConfigContainer scope={consentContext}>
            <LayoutRoot>
                <Helmet title={data.site.siteMetadata.title} meta={meta} />
                {typeof document !== 'undefined' && !document.location.pathname.endsWith('/print') && (
                    <Top title={data.site.siteMetadata.title} />
                )}
                <LayoutMain>{children}</LayoutMain>
            </LayoutRoot>
        </ConsentConfigContainer>
    )
}

export default IndexLayout
