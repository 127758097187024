import { AccountActions, RootStore } from '@signatu/app'
import { LogType } from '@signatu/common-lib'
import { navigate } from 'gatsby'
import { connect } from 'react-redux'
import Top from './top'

const mapStateToProps = (state: RootStore) => {
    const { config, taskStore } = state

    const { accessToken, isLoggedIn, userId, user, isInLoginProcess, roles, isAdmin } = state.account

    return {
        config,
        isLoggedIn,
        isInLoginProcess,
        userId,
        token: accessToken,
        user,
        userRoles: roles,
        taskCount: Object.values(taskStore.modelsById).filter((t: any) => !t.completed).length,
        isAdmin,
    }
}

const mapDispatchToProps = (dispatch) => ({
    navigate,
    loadUser: (userId: string) => dispatch(AccountActions.loadCurrentUser(userId)),
    log: (level: LogType, message: string) => dispatch(AccountActions.log(level, message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Top)
