
import React from 'react';
import { connect } from 'react-redux';

import UserBadge, { UserBadgeProps } from './user-badge';

const mapStateToProps = (state, props) => {
  const { account: { user } } = state
  return {
    user
  }
}

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(UserBadge)
