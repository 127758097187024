/** Load the current user if token exists in store but no user */
import { AccountActions, RootStore } from '@signatu/app'
import { withLoader } from '@signatu/common-react'
import { connect } from 'react-redux'

const mapStateToProps = (state: RootStore, props) => {
    const { account } = state
    const { accessToken, user, userId } = account
    return {
        accessToken,
        userId,
        user,
    }
}

const mapDispatchToProps = dispatch => ({
    loadUser: (userId: string) => dispatch(AccountActions.loadCurrentUser(userId)),
})

export const loader = async (props, prevProps?) => {
    const { accessToken, user, userId, loadUser } = props
    if (accessToken && userId && !user) {
        await loadUser(userId)
    }
}
const Component = ({ children }) => children
const loaded = withLoader(loader)(Component)

export default connect(mapStateToProps, mapDispatchToProps)(loaded)
