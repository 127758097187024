import React from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core'
import { FC } from 'react'
import classNames from 'classnames'

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            height: '100%',
            overflow: 'auto',
        },
    })

interface PageProps {
    className?: string
}

const Page: FC<PageProps & WithStyles<typeof styles>> = ({ classes, children, className }) => (
    <div className={classNames(classes.root, className)}>{children}</div>
)

export default withStyles(styles)(Page)
