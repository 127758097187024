import { NotFound } from '@signatu/common-react'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core'
import React, { FC } from 'react'

import Page from '../components/page'
import IndexLayout from '../layouts/index-layout'

const styles = (theme: Theme) =>
    createStyles({
        root: {},
    })

export interface NotFoundProps {
    className?: string
}

const NotFoundPage: FC<WithStyles<typeof styles> & NotFoundProps> = ({ className, classes }) => (
    <IndexLayout>
        <Page>
            <NotFound />
        </Page>
    </IndexLayout>
)

export default withStyles(styles)(NotFoundPage)
