import React from 'react'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import HomeIcon from '@material-ui/icons/HomeOutlined'
export interface Menu {
    name: string
    link: string
    /**
     * If undefined, show in both logged in and logged out cases
     * If false, render iff not logged in.
     * If true, render iff logged in
     */
    loggedIn?: boolean
    useButton?: boolean

    /** Hide when breakpoint is < 'sm' */
    hideSmall?: boolean

    /** Show when in app - i.e., path /app/* */
    hideInApp?: boolean
    children?: Menu[]
    icon?: React.ReactElement
}

const topMenu: Menu[] = [
    {
        name: 'Home',
        link: '/',
        icon: React.createElement(HomeIcon),
    },
    {
        name: 'Technical Docs',
        link: '/docs',
        loggedIn: true,
    },
    {
        name: 'Legal Guides',
        link: '/legal/guides',
        loggedIn: true,
    },
    // {
    //     name: "Try Signatu",
    //     link: "/app/signup",
    //     loggedIn: false,
    //     useButton: true,
    // },
    {
        name: 'Login',
        link: '/app/',
        loggedIn: false,
        useButton: true,
        icon: React.createElement(LockOutlinedIcon),
    },
    {
        name: 'Go to App',
        link: '/app/',
        loggedIn: true,
        hideInApp: true,
        useButton: true,
    },
]

export default topMenu
