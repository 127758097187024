import React from 'react'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core'
import classNames from 'classnames'
import Container from '../components/container'

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 64px)',
        },
    })
interface LayoutMainProps {
    className?: string
}

const LayoutMain: React.FC<LayoutMainProps & WithStyles<typeof styles>> = ({ children, className, classes }) => (
    <Container className={classNames(className, classes.root)}>{children}</Container>
)

export default withStyles(styles)(LayoutMain)
